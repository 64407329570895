<template>
  <div>
    <v-card-text class="d-flex align-center flex-wrap" v-if="integrations.length">
      <v-card-text class="px-0">
        <v-card flat>
          <v-card-text class="d-flex align-center flex-wrap pb-md-0 px-0">
            <v-card-title>
              <v-icon class="mr-1">{{ icons.mdiKey }}</v-icon> Chaves
            </v-card-title>
            <v-spacer></v-spacer>
            <div class="d-flex align-center flex-wrap">
              <v-btn color="success text-capitalize" @click="handleIntegration()">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                <span>Nova Chave</span>
              </v-btn>
            </div>
          </v-card-text>
          <v-card-text class="px-0">
            <v-data-table
              :items-per-page="25"
              :page.sync="pageCount"
              :headers="integrationsTableColumns"
              :items="integrations"
              @page-count="pageCount = $event"
              :footer-props="{
                itemsPerPageOptions: [25,50,100,-1],
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageAllText: 'Todos',
                disablePagination: true,
                nextIcon: '',
                prevIcon: '',
                pageText: ''
              }"   
            >
            <template v-slot:item.token="{ item }">
              <div class="d-flex align-center" style="width: 900px">
                <p class="overflow-x-hidden text-truncate">
                  {{ item.token }}
                </p>
              </div>
            </template>
              <template v-slot:item.type="{ item }">
                <div class="d-flex align-center">
                  <v-chip
                    label
                    outlined
                    :color="item.type == 'webhook' ? 'success' : 'accent'"
                  >
                    <v-icon class="mr-1">
                      {{
                        item.type == "webhook"
                          ? icons.mdiBellRing
                          : icons.mdiCog
                      }}
                    </v-icon>
                    <small>
                      {{ item.type }}
                    </small>
                  </v-chip>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="handleIntegration(item.id)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2">
                          {{ icons.mdiFileDocumentOutline }}
                        </v-icon>
                        <span>Editar</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title @click="deleteIntegrationKey(item)">
                        <v-icon size="20" class="me-2">
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span>Inativar</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:footer class="d-flex">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card-text>
    <nothing-component v-if="!integrations.length && !loading">
      <v-btn color="success text-capitalize ma-4" @click="handleIntegration()">
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Nova Chave</span>
      </v-btn>
    </nothing-component>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <integration-form ref="integrationForm" @onSubmit="getIntegrations"/>
  </div>
</template>

<script>
import {
  mdiBellRing,
  mdiBriefcase,
  mdiCog,
  mdiConnection,
  mdiContentSave,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileCheck,
  mdiFileDocumentOutline,
  mdiFountainPen,
  mdiInformation,
  mdiKey,
  mdiMapMarker,
  mdiPhone,
  mdiTextBox,
  mdiPlus
} from "@mdi/js";

import IntegrationForm from "@/components/integrations/form/IntegrationForm.vue";

import NothingComponent from '@/components/nothingComponent.vue';

export default {
  components: { IntegrationForm, NothingComponent },

  data() {
    return {
      loading: false,
      dirty: {
        integrations: false,
      },

      icons: {
        mdiInformation,
        mdiMapMarker,
        mdiPhone,
        mdiTextBox,
        mdiContentSave,
        mdiBriefcase,
        mdiFountainPen,
        mdiFileCheck,
        mdiConnection,
        mdiBellRing,
        mdiCog,
        mdiKey,
        mdiDeleteOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiPlus
      },

      integrationsTableColumns: [
        { text: "Plataforma", value: "platform" },
        { text: "Cliente Id", value: "client_id" },
        { text: "Segredo", value: "client_secret" },
        { text: "Token", value: "token" },
        { text: "Tipo", value: "type" },
        { text: "Ações", value: "actions" }
      ],

      integrations: [],
      page: null,
      pageCount: null
    };
  },

  mounted() {
    this.getIntegrations();
  },

  methods: {
    getIntegrations() {
      this.loading = true
      this.$http
        .$get("integration")
        .then((response) => {
          this.integrations = response.data.data;
          this.loading = false
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleIntegration(row) {
      this.$refs.integrationForm.open(row);
    },
  }
};
</script>

<style>
</style>