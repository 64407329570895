<template>
  <div id="user-view">
    <h1 class="ma-2 ml-0">Minha Empresa</h1>
    <v-col class="px-0">
      <v-tabs class="user-tabs mb-5" v-model="tab" show-arrows center-active>
        <v-tab>
          <v-icon class="ma-1">
            {{ icons.mdiBriefcase }}
          </v-icon>
          Empresa
        </v-tab>
        <v-tab>
          <v-icon class="ma-1">
            {{ icons.mdiConnection }}
          </v-icon>
          Integrações
        </v-tab>
        <v-tab>
          <v-icon class="ma-1">
            {{ icons.mdiApi }}
          </v-icon>
          API
        </v-tab>
        <v-tab>
          <v-icon class="ma-1">
            {{ icons.mdiFountainPen }}
          </v-icon>
          Assinaturas
        </v-tab>
        <v-tab>
          <v-icon class="ma-1">
            {{ icons.mdiFileCheck }}
          </v-icon>
          Configurações Fiscais
        </v-tab>
        <v-tab>
          <v-icon class="ma-1">
            {{ icons.mdiTextBox }}
          </v-icon>
          Certificado Digital
        </v-tab>
        <v-tab>
          <v-icon class="ma-2">
            {{ icons.mdiBarcodeScan }}
          </v-icon>
          Faturamento Automático
        </v-tab>
      </v-tabs>
    </v-col>
    <v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div>
            <v-card-text>
              <div class="row col-xs pb-0">
                <div class="col-md">
                  <div class="d-flex justify-space-between align-center">
                    <v-card-title class="py-4 pl-0">
                      <v-icon class="ma-1">{{ icons.mdiInformation }}</v-icon>
                      Informações
                    </v-card-title>
                    <v-chip small class="ma-1" color="primary">
                      {{ companyType }}
                    </v-chip>
                  </div>
                  <company-form
                    :initialData="infoForm"
                    @submit="handleInfo"
                    ref="company"
                    :dense="true"
                  ></company-form>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="row">
                <div class="col-md">
                  <v-card-title>
                    <v-icon class="ma-1">{{ icons.mdiMapMarker }}</v-icon>
                    Endereço</v-card-title
                  >
                  <address-component
                    ref="address"
                    :initialData="addressForm"
                    @submit="handleAddress"
                    :dense="true"
                  ></address-component>
                </div>
              </div>
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <v-card-text class="d-flex justify-md-end justify-center">
            <v-btn color="success" @click="submitCompanyInfo">
              <v-icon>{{ icons.mdiContentSave }}</v-icon>
              {{ isSaving ? "Salvando..." : "Salvar Informações" }}
            </v-btn>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <integration-list />
        </v-tab-item>

        <v-tab-item>
          <company-api-vue :apiKey="apiKeyInfo"/>
        </v-tab-item>

        <v-tab-item>
          <subscription-list />
        </v-tab-item>

        <v-tab-item>
          <company-nfe-settings />
        </v-tab-item>

        <v-tab-item>
          <digital-certificate :initialData="infoForm" />
        </v-tab-item>

        <v-tab-item>
          <automatic-billing
            :companyData="infoForm"
            :addressData="addressForm"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiBellRing,
  mdiBriefcase,
  mdiCog,
  mdiConnection,
  mdiContentSave,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileCheck,
  mdiFileDocumentOutline,
  mdiFountainPen,
  mdiInformation,
  mdiKey,
  mdiMapMarker,
  mdiPhone,
  mdiTextBox,
  mdiPlus,
  mdiBarcodeScan,
  mdiApi,
} from "@mdi/js";

import addressComponent from "../../components/app/address/addressComponent.vue";
import CompanyForm from "@/components/administration/company/forms/CompanyForm.vue";
import IntegrationList from "@/components/integrations/list/IntegrationList.vue";
import SubscriptionList from "@/components/subscriptions/list/SubscriptionList.vue";
import CompanyNfeSettings from "@/components/nfeSettings/form/CompanyNfeSettings.vue";
import DigitalCertificate from "@/components/administration/company/forms/DigitalCertificate.vue";
import AutomaticBilling from "@/components/administration/company/AutomaticBilling.vue";
import CompanyApiVue from '@/components/CompanyApi/CompanyApi.vue';
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { PAYLOAD_DEFAULT } from "@/constants/option";

export default {
  props: {
    initialData: [],
  },

  components: {
    addressComponent,
    CompanyForm,
    IntegrationList,
    SubscriptionList,
    CompanyNfeSettings,
    DigitalCertificate,
    AutomaticBilling,
    VueJsonPretty,
    CompanyApiVue
  },

  data() {
    return {
      payload_default: PAYLOAD_DEFAULT,
      tab: 0,
      isSaving: false,

      icons: {
        mdiInformation,
        mdiMapMarker,
        mdiPhone,
        mdiTextBox,
        mdiContentSave,
        mdiBriefcase,
        mdiFountainPen,
        mdiFileCheck,
        mdiConnection,
        mdiBellRing,
        mdiCog,
        mdiKey,
        mdiDeleteOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiTextBox,
        mdiBarcodeScan,
        mdiApi,
      },

      infoForm: {
        cnpj: null,
        name: null,
        alias: null,
        inscricao_estadual: null,
        inscricao_municipal: null,
        inscricao_estadual_isento: null,
        phone: null,
        whatsapp: null,
        email: null,
        site: null,
        automatic_invoice: null,
        is_affiliated: null,
        is_producer: null,
        is_dropshipper: null,
        apiKey:null
      },

      addressForm: {
        cep: null,
        number: null,
        street: null,
        neighborhood: null,
        complement: null,
        state: null,
        city: null,
        city_code: null,
      },

      apiKeyInfo:{
        platform:null,
        token:null,
        type:null
      },

      certificate: null,
    };
  },

  computed: {
    companyInfo() {
      return {
        cnpj: this.infoForm.cnpj,
        name: this.infoForm.name,
        alias: this.infoForm.alias,
        inscricao_estadual: this.infoForm.inscricao_estadual,
        inscricao_municipal: this.infoForm.inscricao_municipal,
        inscricao_estadual_isento: this.infoForm.inscricao_estadual_isento,
        phone: this.infoForm.phone,
        whatsapp: this.infoForm.whatsapp,
        email: this.infoForm.email,
        site: this.infoForm.site,
        address: this.addressForm,
        automatic_invoice: this.infoForm.automatic_invoice,
        is_affiliated: this.infoForm.is_affiliated,
        is_producer: this.infoForm.is_producer,
        is_dropshipper: this.infoForm.is_dropshipper,
      };
    },

    companyType() {
      if (this.infoForm.is_affiliated == true) {
        return "Afiliado";
      }

      if (this.infoForm.is_producer == true) {
        return "Produtor";
      }

      if (this.infoForm.is_dropshipper == true) {
        return "Dropship";
      }

      return "Não configurado";
    },
  },

  mounted() {
    this.infoForm = {
      ...this.$store.state.auth.user.company,
    };

    this.addressForm = {
      ...this.$store.state.auth.user.company.main_address,
    };

    this.apiKeyInfo = {
      ...this.$store.state.auth.user.company.api_key,
    }
  },

  methods: {
    handleInfo(e) {
      this.infoForm = e;
    },

    handleAddress(e) {
      this.addressForm = e;
    },

    submitCompanyInfo() {
      let companyFormValid = this.$refs.company.validate();
      let addressFormValid = this.$refs.address.validate();
      if (companyFormValid && addressFormValid) {
        this.$http
          .$put(
            `company/${this.$store.state.auth.user.company.id}`,
            this.companyInfo
          )
          .then((response) => {
            this.$router.go();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/apps/user.scss";
</style>
