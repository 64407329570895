<template>
  <div>
    <v-card-title>
      <v-icon class="ma-2">{{ icons.mdiTextBox }}</v-icon>
      Certificado Digital
    </v-card-title>
    <div>
      <v-data-table
            :items-per-page="25"
            :items="certificate"
            item-key="id"
            :headers="tableColumns"
            loading-text="Carregando dados..."
          >
      </v-data-table>
    </div>
      <div cols="12">
        <v-btn
          @click="() => { dialog = true}"
          color="success"
          class="text-capitalize ma-6"
        >
          <v-icon>
            {{ icons.mdiFileDocumentOutline }}
          </v-icon>
          Enviar Certificado
        </v-btn>
      </div>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        origin="center center"
        persistent
        :fullscreen="isMobile"
      >
        <v-card>
          <v-card-title> Cadastrar Certificado </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <div class="row col-xs">
                <div class="col">
                  <v-file-input
                    v-model="file"
                    label="Selecionar arquivo..."
                    outlined
                    dense
                    :rules="[rules.required]"
                    hide-details="auto"
                    @change="setFile($event)"
                  ></v-file-input>
                </div>
                <div class="col">
                  <v-text-field
                    v-model="password"
                    outlined
                    dense
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Senha"
                    placeholder="Senha"
                    :rules="[rules.required]"
                    :append-icon="passwordVisibleIcon"
                    hide-details="auto"
                    class="mb-6"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </div>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="ma-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="dialog = false"
              class="text-capitalize"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              @click="submitCertificate()"
              class="text-capitalize"
            >
              <v-icon>
                {{ icons.mdiContentSave }}
              </v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import {
  mdiContentSave,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiTextBox,
} from "@mdi/js";
import formMixin from '@/util/mixins/formMixin';

export default {
  props: {
    initialData: {}
  },

  mixins: [formMixin],
  
  data() {
    return {
      dialog: false,
      isPasswordVisible: false,

      icons: {
        mdiFileDocumentOutline,
        mdiContentSave,
        mdiTextBox,
      },

        tableColumns: [
        { text: "Arquivo", value: "original_file_name" },
        { text: "Validade", value: "valid_to_date" },
        { text: "Emissão", value: "valid_from_date" },
      ],

      certificate: [
        
      ],

      password: null,

      file: null,
    };
  },

   mounted(){
    this.file = this.initialData.file;
    this.certificate.push(this.initialData.certificate);
  },

   watch:{
    initialData(newData){
      this.file = this.newData.file;
      this.certificate.push(newData.certificate);
    },
  },

  computed: {
    passwordVisibleIcon() {
      return this.isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline;
    },
  },

  methods: {
    setFile(event) {
      this.file = event;
    },
    submitCertificate() {
      console.log(this.initialData)
      if (this.$refs.form.validate()) {
      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("password", this.password);
      formData.append("company_id", this.initialData.id);
      formData.append("filename", this.file.name);

      this.$http
        .$post(`certificate`, formData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
        
      this.dialog = false;
      }
    },
  },
};
</script>

<style>
</style>