var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Pacotes")]),_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('div',{staticClass:"row"}),_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":function($event){return _vm.newPackage()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Novo Pacote")])],1)],1)]),_c('v-data-table',{attrs:{"items-per-page":20,"item-key":"id","headers":_vm.tableColumns,"search":_vm.searchQuery,"items":_vm.packages,"loading":_vm.loading,"loading-text":"Carregando Tabela...","footer-props":{
        itemsPerPageOptions: [20, 50, 100, -1],
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageAllText: 'Todos',
        disablePagination: true,
        nextIcon: '',
        prevIcon: '',
        pageText: '',
      }},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'packages', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolvePackageStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolvePackageStatusVariant(item.status)}},[(item.status === 1)?_c('div',[_vm._v("Pendente")]):_vm._e(),(item.status === 2)?_c('div',[_vm._v("Ativo")]):_vm._e(),(item.status === 3)?_c('div',[_vm._v("Inativo")]):_vm._e()])]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.resolvePackageTypeIcon(item.type))+" ")])],1),_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",attrs:{"small":""}},[(item.type === 1)?_c('div',[_vm._v("Caixa")]):_vm._e(),(item.type === 2)?_c('div',[_vm._v("Rolo")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editPackage(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),(item.status == 2)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.inactivePackage(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Inativar")])],1)],1):_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.activePackage(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRestore)+" ")]),_c('span',[_vm._v("Reativar")])],1)],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"paginate",attrs:{"length":_vm.lastPage,"total-visible":"6"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})]},proxy:true}])})],1),_c('PackageForm',{ref:"packageForm",on:{"onSubmit":_vm.getPackages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }