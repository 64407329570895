<template>
  <div>
      <v-card-text class="d-flex align-center flex-wrap pa-8" style="gap: 25px" v-if="subscription[0]">
        <div v-for="row in subscription" :key="row.id" class="row ma-0">
          <v-card class="col ma-0 mr-3" elevation="1">
            <v-card-text class="d-flex" style="font-size: small">
              <div>
                <span style="font-size: small">
                  Identificador Iugu
                </span>
                <br>
                <span style="font-size: dense">
                  {{ row.iugu_consumer_id }} 
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon color="accent">
                {{ icons.mdiAccount }}
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card class="col ma-0 mx-3" elevation="1">
            <v-card-text class="d-flex" style="font-size: small">
              <div>
                <span style="font-size: small">
                  Assinatura da Iugu
                </span>
                <br>
                <span style="font-size: dense">
                  {{ row.iugu_subscription_id }}
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon color="warning">
                {{ icons.mdiFileDocumentEdit   }}
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card class="col ma-0 ml-3" elevation="1">
            <v-card-text class="d-flex" style="font-size: small">
              <div>
                <span style="font-size: small">
                  Assinatura
                </span>
                <br>
                <span style="font-size: dense">
                  {{ row.active && !row.suspended ? 'Ativo' : 'Inativo' }}
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon :color="row.active && !row.suspended ? 'success' : 'error'">
                {{ icons.mdiAccountCheck }}
              </v-icon>
            </v-card-text>
          </v-card>
        </div>
        <v-card-text class="d-flex align-center flex-wrap pa-0">
          <v-spacer></v-spacer>
          <div class="d-flex align-center flex-wrap" style="gap: 10px">
            <v-btn
              class="text-capitalize success"
              @click="upgradeSubscription(subscription[0])"
              v-if="subscription[0].active && !subscription[0].suspended"
            >
              <v-icon size="17" class="me-1">
                {{ icons.mdiSync }}
              </v-icon>
              <span>Atualizar assinatura</span>
            </v-btn>
            <v-btn
              color="white"
              :disabled="false"
              outlined
              :class="
              subscription[0].active && !subscription[0].suspended
                ? 'error'
                : 'success'
              "
              class="text-capitalize"
              @click="
                subscription[0].active && !subscription[0].suspended
                  ? cancelSubscription(subscription[0])
                  : activateSubscription(subscription[0])
              "
            >
              <v-icon size="17" class="me-1">{{ icons.mdiCheck }}</v-icon>
              <span>{{subscription[0].active && !subscription[0].suspended? "Cancelar" : "Ativar" }} assinatura</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text class="pa-0">
          <v-card flat>
            <v-card-text class="pa-0">
              <v-data-table
                v-if="subscription[0].active && !subscription[0].suspended"
                :headers="integrationsTableColumns"
                :items="subscription[0].payments"
                :loading="loading"
                :items-per-page="25"
                :page.sync="pageCount"
                :footer-props="{
                  itemsPerPageOptions: [25,50,100,-1],
                  itemsPerPageText: 'Itens por pagina',
                  itemsPerPageAllText: 'Todos',
                  disablePagination: true,
                  nextIcon: '',
                  prevIcon: '',
                  pageText: ''
                }"   
              >
                <template v-slot:item.invoice_url="{ item }">
                  <span v-if="item.invoice_url">
                    <a :href="item.invoice_url" target="_blank">Link da fatura</a>
                  </span>
                </template>

                <template v-slot:item.due_date="{ item }">
                  {{ item.due_date | dateBR }}
                </template>

                <template v-slot:item.amount="{ item }">
                  {{ (item.amount / 100) | moneyBr }}
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip
                    small
                    class="font-weight-semibold text-capitalize"
                    :color="resolveStatusVariant(item.status)"
                  >
                    <div v-if="item.status === 'draft'">Rascunho</div>
                    <div v-if="item.status === 'pendding'">Pendente</div>
                    <div v-if="item.status === 'billed'">Faturado</div>
                    <div v-if="item.status === 'cancelled'">Cancelado</div>
                  </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                          v-if="item.status == 'pendding'"
                          @click="duplicateInvoice(item)"
                        >
                          <v-list-item-title>
                            <v-icon size="20" class="me-2">
                              {{ icons.mdiFileDocumentOutline }}
                              </v-icon>
                          <span>Segunda via</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="updateInvoice(item)">
                        <v-list-item-title>
                          <v-icon size="20" class="me-2">
                            {{ icons.mdiReload }}
                          </v-icon>
                          <span>Atualizar fatura</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="handleIntegration(item.id)">
                        <v-list-item-title>
                          <v-icon size="20" class="me-2">
                            {{ icons.mdiFileDocumentOutline }}
                          </v-icon>
                          <span>Editar</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title @click="deleteIntegrationKey(item)">
                          <v-icon size="20" class="me-2">
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                          <span>Inativar</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:footer class="d-flex">
                  <v-pagination 
                    v-model="page" 
                    :length="pageCount"
                  ></v-pagination>
                </template>

              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <integration-form ref="integrationForm" />
      </v-card-text>
      <nothing-component v-if="subscription[0] && !loading"></nothing-component>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <subscription-form ref="subscriptionForm" @onSubmit="upgradeSubscription" />
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiAccount,
  mdiAccountCheck,
  mdiFileDocumentEdit,
  mdiReload,
} from "@mdi/js";

import subscriptionMixin from "@/util/mixins/subscriptionMixin";
import IntegrationForm from "@/components/integrations/form/IntegrationForm.vue";
import nothingComponent from "@/components/nothingComponent.vue";
import SubscriptionForm from "@/components/subscriptions/forms/SubscriptionForm.vue";
import Swal from "sweetalert2";

export default {
  components: { IntegrationForm, nothingComponent, SubscriptionForm },
  mixins: [subscriptionMixin],

  data() {
    return {
      loading: false,
      page: null,
      pageCount: null,

      dirty: {
        integrations: false,
      },

      icons: {
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiAccount,
        mdiAccountCheck,
        mdiFileDocumentEdit,
        mdiReload,
      },

      integrationsTableColumns: [
        { text: "Fatura", value: "invoice_url" },
        { text: "Data vencimento", value: "due_date" },
        { text: "Valor", value: "amount" },
        { text: "Método do pagamento", value: "payment_method" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions" },
      ],

      integrations: [],

      subscription: {
        iugu_consumer_id: null,
        iugu_subscription_id: null,
        active: null
      }

    };
  },

  mounted() {
    this.getSubscriptionAndPayments();
    this.getIntegrations();
  },

  methods: {
    getSubscriptionAndPayments() {
      this.loading = true

      this.$http
        .index("subscription")
        .then((response) => {
          this.subscription = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getIntegrations() {
      this.loading = true;
      this.$http
        .$get("integration")
        .then((response) => {
          this.integrations = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateInvoice(invoice) {
      this.$http
        .update(`/subscription/${this.subscription[0].id}/update-invoice/${invoice.id}`, [])
        .then((response) => {
          this.getSubscriptionAndPayments();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    duplicateInvoice(invoice) {
      this.$http
        .store(`/subscription/${this.subscription[0].id}/duplicate-invoice/${invoice.id}`, {
          id: invoice.request.id,
          due_date: new Date(),
        })
        .then((response) => {
          this.getpayments();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleIntegration(row) {
      this.$refs.integrationForm.open(row);
    },

    cancelSubscription(subscription) {
      console.log(subscription);
      Swal.fire({
        title: "Desejar mesmo cancelar sua assinatura?",
        icon: "warning",
        text: "Algumas funções poderam ser bloqueadas após esta ação",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#56ca02",
        cancelButtonColor: "#ff4c51",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http
            .store(`subscription/${subscription.id}/cancel`)
            .then((response) => {
              this.getSubscriptionAndPayments();
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                background: "success",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Alterações realizadas com sucesso!",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            background: "info",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "info",
            title: "Alterações não foram realizadas!",
          });
        }
      });
    },

    activateSubscription(subscription) {
      console.log(subscription);
      Swal.fire({
        title: "Desejar mesmo ativar sua assinatura?",
        icon: "warning",
        text: "Novas faturas poderão ser geradas",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#56ca02",
        cancelButtonColor: "#ff4c51",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http
            .store(`subscription/${subscription.id}/activate`)
            .then((response) => {
              this.getSubscriptionAndPayments();
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                background: "success",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Alterações realizadas com sucesso!",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            background: "info",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "info",
            title: "Alterações não foram realizadas!",
          });
        }
      });
    },

    upgradeSubscription(subscription) {
      this.$refs.subscriptionForm.open(subscription).then(this.getpayments());
    },
  },
};
</script>

<style>
</style>