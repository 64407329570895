<template>
  <div>
    <h1 class="ma-2 ml-0">Pacotes</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row">
        </div>
        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="newPackage()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Pacote</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="20"
        item-key="id"
        :headers="tableColumns"
        :search="searchQuery"
        :items="packages"
        :loading="loading"
        loading-text="Carregando Tabela..."
        @page-count="pageCount = $event"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }"
      >
        <!-- name -->
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'packages', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- status -->
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :color="resolvePackageStatusVariant(item.status)"
            :class="`${resolvePackageStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Pendente</div>
            <div v-if="item.status === 2">Ativo</div>
            <div v-if="item.status === 3">Inativo</div>
          </v-chip>
        </template>
        
        <!-- type -->
        <template v-slot:item.type="{ item }">
          <v-avatar
            size="30"
          >
            <v-icon size="18">
              {{ resolvePackageTypeIcon(item.type) }}
            </v-icon>
          </v-avatar>
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.type === 1">Caixa</div>
            <div v-if="item.type === 2">Rolo</div>
          </v-chip>
        </template>

        <!-- actions -->
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editPackage(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status == 2"
                link
                @click="inactivePackage(item)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Inativar</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else link @click="activePackage(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Reativar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:footer class="d-flex">
          <v-pagination
            class="paginate"
            v-model="currentPage"
            :length="lastPage"
            total-visible="6"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <PackageForm ref="packageForm" @onSubmit="getPackages" />
  </div>
</template>
  
  <script>
import {
  mdiDeleteOutline,
  mdiPackageVariantClosed,
  mdiDatabaseOutline,
  mdiPlus,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiRestore,
} from "@mdi/js";

import PackageForm from "@/components/transport/package/forms/PackageForm";

import tableMixin from "@/util/mixins/tableMixin";

export default {
  name: "packages",
  components: { PackageForm },

  mixins: [tableMixin],

  data() {
    return {
      selectedRows: [],
      packages: [],
      roles: "",
      searchQuery: null,
      loading: false,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      page: null,
      pageCount: null,
      currentPage: 1,
      lastPage: null,

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPackageVariantClosed,
        mdiDatabaseOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiRestore,
      },

      tableColumns: [
        { text: "NOME", value: "name" },
        { text: "DESCRIÇÃO", value: "description" },
        { text: "STATUS", value: "status", sortable: false },
        { text: "TIPO", value: "type", sortable: false },
        {
          text: "ACTIONS",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  created() {
    this.getPackages();
  },
  watch: {
    currentPage(newValue) {
      this.currentPage = newValue;
      this.getPackages();
    },
  },

  methods: {
    getPackages() {
      this.loading = true;
      this.$http
        .index("/packages", {
          page: this.currentPage
        })
        .then((response) => {
          if (response.status == 200) {
            this.packages = response.data.data;
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resolvePackageStatusVariant(status) {
      if (status === 1) return "warning";
      if (status === 2) return "success";
      if (status === 3) return "secondary";

      return "primary";
    },

    resolvePackageTypeIcon(type) {
      if (type === 1) return mdiPackageVariantClosed;
      if (type === 2) return mdiDatabaseOutline;

      return mdiPackageVariantClosed;
    },

    newPackage() {
      this.$refs.packageForm.open();
    },

    editPackage(content) {
      this.$refs.packageForm.open(content.id);
    },

    inactivePackage(content) {
      this.package = content;
      this.package.status = 3;
      this.$http
        .$put(`/packages/${content.id}/status`, {
          status: this.package.status
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    activePackage(content) {
      this.package = content;
      this.package.status = 2;
      this.$http
        .$put(`/packages/${content.id}/status`, {
          status: this.package.status
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
  <style>
</style>
  