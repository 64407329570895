<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>Atualizar assinatura</v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiFileDocumentOutline }}
        </v-icon>
        <small>Selecione o novo plano</small>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2" />
      </div>
      <v-form v-show="!loading" v-model="formValid" ref="form">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md">
              <v-select
                v-model="newPlan"
                :items="plans"
                :rules="[rules.required]"
                filled
                item-text="name"
                item-value="id"
                label="Planos"
                outlined
              >
              </v-select>
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary " class="text-capitalize" @click="close">
          Cancelar
        </v-btn>
        <v-btn color="success" class="text-capitalize" @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiAccount,
  mdiMapMarker,
  mdiContentSave,
  mdiFileDocumentOutline,
} from "@mdi/js";
import formMixin from "@/util/mixins/formMixin";

export default {
  name: "SubscriptionForm",

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: null,
      isUpdating: false,
      newPlan: null,
      cadastroModel: null,
      loading: false,
      plans: [],
      icons: {
        mdiAccount,
        mdiContentSave,
        mdiMapMarker,
        mdiFileDocumentOutline,
      },
      subscriptionForm: {
        plans: null,
      },
      subscription: null,
    };
  },

  mounted() {
    this.getPlans();
  },

  methods: {
    open(subscription) {
      this.dialog = true;
      this.subscription = subscription;
    },
    getPlans() {
      this.loading = true;

      this.$http
        .index("plans")
        .then((response) => {
          this.plans = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
    },

    submitForm() {
      let formValid = this.$refs.form.validate();
      console.log(this.newPlan);
      if (formValid) {
        this.$http
          .$post(
            `/subscription/${this.subscription.id}/change-plan/${this.newPlan}`
          )
          .then((res) => {
            console.log('teste')
            if (res.status == 200) {
              console.log('teste 200')
              this.dialog = false;
              console.log(this.dialog)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.reset()
      }
    },
  },
};
</script>

<style>
</style>