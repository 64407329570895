<template>
  <div>
    <v-card-text>
      <v-card-title class="py-4 pl-4">
        <v-icon>{{ icons.mdiInformation }}</v-icon>
        <strong>Integração via API:</strong>
      </v-card-title>
      <v-card-text>
        <p v-if="tokenApi"
          ><strong>token_api:</strong> {{ tokenApi }}</p
        >
        <v-btn
          v-else
          color="success"
          class="ma-sm-2 my-2 text-capitalize"
          small
          @click="storeApiKey()"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Nova chave api</span>
        </v-btn>
      </v-card-text>
      <v-card-text>
        <div>
          <h3>POST: https://api.ezcont.com.br/api/order-api</h3>
        </div>

        <div class="mt-4">
          <vue-json-pretty  :data="payload">
          </vue-json-pretty>
        </div>
      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
import { mdiInformation, mdiPlus } from "@mdi/js";

import { PAYLOAD_DEFAULT } from "@/constants/option";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";



export default {
  props: {
    apiKey: {},
  },

  components: {
    VueJsonPretty,
  },

  data() {
    return {
      payload: PAYLOAD_DEFAULT,

      tokenApi: null,

      icons: {
        mdiInformation,
        mdiPlus,
      },
    };
  },

  mounted() {
    this.tokenApi = this.apiKey.token;
  },

  methods: {
    storeApiKey() {
      this.$http
        .$post(`company/api-key/${this.$store.state.auth.user.company.id}`)
        .then((response) => {
          this.$router.go();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>