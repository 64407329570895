<template>
    <v-app>
        <h1> Faturamento Automático</h1>
        <p class="mt-6 font-weight-bold text-xl text-uppercase">
            Ao habilitar o faturamento automático você concorda que todas as vendas serão faturadas 30 dias após a sua efetivação <br/>
            nas plataformas e que todas as informações da transação como endereço e dados do consumidor final informados são de sua responsabilidade.<br/>
            Após a emissão da NF o cancelamento só é permitido mediante a solicitação e autorização da prefeitura.
        </p>

        <v-switch
        class="ml-2"
        v-model="company.automatic_invoice"
        inset
        label="Habilitar faturamento automático"
        @change="handleAutomaticInvoice()"
    ></v-switch>
    </v-app>
</template>

<script>
export default {
    props: {
        companyData: {},
        addressData: {}
    },

    data() {
        return {
            company: null,
            automatic_invoice: null,
        }
    },

    mounted() {
        this.company = {
            cnpj: this.companyData.cnpj,
            name: this.companyData.name,
            alias: this.companyData.alias,
            inscricao_estadual: this.companyData.inscricao_estadual,
            inscricao_municipal: this.companyData.inscricao_municipal,
            inscricao_estadual_isento: this.companyData.inscricao_estadual_isento,
            phone: this.companyData.phone,
            whatsapp: this.companyData.whatsapp,
            email: this.companyData.email,
            site: this.companyData.site,
            automatic_invoice: this.companyData.automatic_invoice,
            address: this.addressData,
        };
    },

    methods: {
        handleAutomaticInvoice() {
            this.$http
            .$put(`company/${this.$store.state.auth.user.company.id}`, this.company)
            .then((response) => {})
            .catch((error) => {
                console.log(error);
            });
        },
    },
    
}
</script>

<style>
</style>