<template>
<div>
  <h1>Galpões</h1>
  <v-card-text class="d-flex align-center flex-wrap" v-if="storages.length">
    <v-card-text class="px-0">
      <v-card flat>
        <v-card-text class="d-flex align-center d-flex flex-row-reverse">
          <v-card-title
          class="d-flex align-center py-sm-2 py-6"
          >
            <div class="row align-center">
                <v-btn color="success text-capitalize" @click="handleStorage()">
                    <v-icon>{{ icons.mdiStorePlus }}</v-icon>
                    <span>Novo Galpão</span>
                </v-btn>
            </div>
          </v-card-title>
        </v-card-text>
        <v-card-text class="px-0">
          <v-data-table
              :items-per-page="25"
              :page.sync="pageCount"
              :headers="storagesTableColumns"
              :items="storages"
              @page-count="pageCount = $event"
              :footer-props="{
                  itemsPerPageOptions: [25,50,100,-1],
                  itemsPerPageText: 'Itens por pagina',
                  itemsPerPageAllText: 'Todos',
                  disablePagination: true,
                  nextIcon: '',
                  prevIcon: '',
                  pageText: ''
              }"  
            >
            <template v-slot:item.status="{ item }">
              <div class="d-flex align-center">
                <v-chip
                  small
                  :color="resolveUserStatusVariant(item.status)"
                  :class="`${resolveUserStatusVariant(item.status)}--text`"
                  class="v-chip-light-bg font-weight-semibold text-capitalize"
                >
                  <div>
                    {{ item.status == 1 ? 'Ativo' : 'Inativo'}}
                  </div>
                </v-chip>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="handleStorage(item.id)">
                    <v-list-item-title>
                      <v-icon size="20" class="me-2">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                      <span>Editar</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="item.status == 1"
                    @click="openAlertConfirmation(item)"
                  >
                    <v-list-item-title>
                      <v-icon size="20" class="me-2">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>Desativar</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else @click="openAlertConfirmation(item)">
                    <v-list-item-title>
                      <v-icon size="20" class="me-2">
                        {{ icons.mdiRestore }}
                      </v-icon>
                      <span>Ativar</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:footer class="d-flex">
              <v-pagination
                v-model="page"
                :length="pageCount"
              ></v-pagination>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card-text>
  <nothing-component v-if="!storages.length && !loading">
    <v-btn color="success text-capitalize ma-4" @click="handleStorage()">
      <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Novo galpão</span>
    </v-btn>
  </nothing-component>
  <div v-if="loading" class="d-flex justify-center">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
  <storage-form ref="storageForm" @onSubmit="getStorages"/>
  <alert
    ref="alert"
    @inactive="updateStatusStorage($event)"
    @active="updateStatusStorage($event)"
  ></alert>
</div>
</template>

<script>
import { mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline, mdiPlus, mdiRestore, mdiStorePlus } from "@mdi/js";
import NothingComponent from '@/components/nothingComponent.vue';
import Alert from "@/components/app/alert/Alert.vue";
import tableMixin from "@/util/mixins/tableMixin";
import storageForm from "@/components/storage/form/StorageForm.vue"

export default {
  components: { NothingComponent, Alert, storageForm },
  mixins: [tableMixin],
  name: "storages",

  data() {
    return {
      loading: false,
      storages: [],

      icons: {
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiPlus,
        mdiRestore,
        mdiStorePlus
      },

      storagesTableColumns: [
        { text: "Nome", value: "name" },
        { text: "Logística", value: "logistic.name" },
        { text: "Cidade", value: "city" },
        { text: "Estado", value: "state" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions" }
      ],

      page: null,
      pageCount: null,
    }
  },

  mounted() {
    this.getStorages()
  },

  methods: {
    getStorages(){
      this.loading = true
      this.$http
          .$get("storages")
          .then((response) => {
              this.storages = response.data.data
              this.loading = false
          })
          .catch((error) => {
              console.log(error)
          });
    },

    handleStorage(row){
      this.$refs.storageForm.open(row)
    },
    
    openAlertConfirmation(row) {
      this.$refs.alert.open(row);
    },

    updateStatusStorage(content) {
      this.$http
      .$put(`/storages/${content.id}`, content)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },
}
</script>

<style scoped>

</style>