<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title
        >{{ isUpdating ? "Editar" : "Cadastrar" }} Pacote</v-card-title
      >
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiArchive }}
        </v-icon>
        <small>Dados</small>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </div>
      <v-form v-if="!loading" v-model="formValid" ref="form">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md-4">
              <v-text-field
                v-model="packageForm.name"
                label="Nome"
                :rules="[rules.required]"
                outlined
                hide-details="auto"
                dense
              />
            </div>
            <div class="col-md-8">
              <v-text-field
                v-model="packageForm.description"
                label="Descrição"
                :rules="[rules.required]"
                outlined
                hide-details="auto"
                dense
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-select
                v-model="packageForm.type"
                :items="packageTypes"
                :rules="[rules.required]"
                label="Tipo de pacote"
                filled
                outlined
                dense
                hide-details="auto"
                @change="resetForm(this)"
              ></v-select>
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="packageForm.weight"
                label="Peso"
                suffix="kg"
                outlined
                dense
                :rules="[rules.required]"
                hide-details="auto"
                type="number"
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6" v-if="packageForm.type == 1">
              <v-text-field
                v-model="packageForm.height"
                label="Altura"
                suffix="cm"
                outlined
                dense
                :rules="
                  packageForm.type == 1 ? [rules.required, rules.between] : []
                "
                :hidden="packageForm.type == 2"
                :min="0"
                :max="99.99"
                hide-details="auto"
                type="number"
              />
            </div>
            <div class="col-md-6" v-if="packageForm.type == 1">
              <v-text-field
                v-model="packageForm.width"
                label="Largura"
                suffix="cm"
                outlined
                dense
                :rules="
                  packageForm.type == 1 ? [rules.required, rules.between] : []
                "
                :hidden="packageForm.type == 2"
                :min="0"
                :max="99.99"
                hide-details="auto"
                type="number"
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6" v-if="packageForm.type == 2">
              <v-text-field
                v-model="packageForm.diameter"
                label="Diâmetro"
                suffix="cm"
                outlined
                dense
                :rules="
                  packageForm.type == 2 ? [rules.required, rules.between] : []
                "
                :hidden="packageForm.type == 1"
                :min="0"
                :max="99.99"
                hide-details="auto"
                type="number"
              />
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="packageForm.length"
                label="comprimento"
                suffix="cm"
                outlined
                dense
                :rules="[rules.required, rules.between]"
                :min="0"
                :max="99.99"
                hide-details="auto"
                type="number"
              />
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close">
          Cancelar
        </v-btn>
        <v-btn class="text-capitalize" color="success" @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiArchive,
  mdiContentSave,
} from "@mdi/js";
import formMixin from "@/util/mixins/formMixin";

const packageForm = {
  name: null,
  description: null,
  status: null,
  weight: null,
  width: null,
  height: null,
  length: null,
  diameter: null,
  type: 0,
};

export default {
  name: "PackageForm",

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      icons: {
        mdiArchive,
        mdiContentSave,
      },
      isUpdating: false,
      formValid: null,
      packageTypes: [
        { text: "Caixa", value: 1 },
        { text: "Rolo", value: 2 },
      ],
      packageForm: packageForm,
      loading: false,

      rules: {
        between: (v) =>
          (v > 0 && v <= 99.99) || "O valor deve estar entre 0 e 99,99.",
      },
    };
  },
  methods: {
    open(id) {
      this.dialog = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (id) {
        this.isUpdating = true;
        this.loading = true;
        this.$http.$get(`/packages/${id}`).then((res) => {
          if (res.status == 200) {
            this.packageForm = res.data;
            this.loading = false;
          }
        });
      }
    },

    close() {
      this.$refs.form.reset();
      this.packageForm = packageForm;
      (this.isUpdating = false), (this.dialog = false);
    },
    resetForm() {
      this.packageForm.weight = null;
      this.packageForm.width = null;
      this.packageForm.height = null;
      this.packageForm.length = null;
      this.packageForm.diameter = null;
    },

    submitForm() {
      let formValid = this.$refs.form.validate();
      if (formValid) {
        if (this.isUpdating) {
          this.$http
            .$put(`/packages/${this.packageForm.id}`, this.packageForm)
            .then((res) => {
              if (res.status == 200) {
                this.dialog = false;
                this.$refs.form.reset();
                this.packageForm = packageForm;
                (this.isUpdating = false), (this.dialog = false);
                this.$emit("onSubmit");
              }
            });
        } else {
          this.$http.$post("/packages", this.packageForm).then((res) => {
            if (res.status == 200) {
              this.dialog = false;
              this.$refs.form.reset();
              this.packageForm = packageForm;
              (this.isUpdating = false), (this.dialog = false);
              this.$emit("onSubmit");
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>
