<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>
        {{ isUpdating ? "Editar" : "Criar " }} Chave
      </v-card-title>
      <div v-show="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2"/>
      </div>
      <v-card-text v-show="!loading">
        <v-form v-model="formValid" ref="form" >
          <div class="row col-xs">
            <div class="col-md-4">
              <v-select
                v-model="form.platform"
                :rules="[rules.required]"
                :items="platforms"
                item-text="platform"
                label="Plataforma"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-4">
              <v-text-field
                v-model="form.client_id"
                label="Id do Cliente"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="form.client_secret"
                label="Segredo"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-8">
              <v-text-field
                v-model="form.token"
                :rules="[rules.required]"
                label="Token de Acesso"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <v-select
                v-model="form.type"
                :items="integrationType"
                :rules="[rules.required]"
                item-text="value"
                label="Tipo"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="text-capitalize"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="text-capitalize"
          color="success"
          @click="submitForm"
        >
          <v-icon>
            {{ icons.mdiContentSave }}
          </v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentSave } from "@mdi/js";
import formMixin from '@/util/mixins/formMixin';

export default {
  name: "integrationform",

  mixins: [formMixin],

  data() {
    return {
      loading: false,
      dialog: false,
      isUpdating: false,
      formValid: true,

      form: {
        client_id: null,
        client_secret: "",
        token: "",
        platform: "",
        type: null,
      },

      platforms: null,

      integrationType: [{ value: "API" }, { value: "Webhook" }],

      icons: {
        mdiContentSave,
      },

    };
  },

  mounted() {
    this.getPlatforms();
  },

  methods: {
    open(id = null) {
      this.dialog = true;
      this.isUpdating = id != null;
      if (id) {
        this.getIntegration(id);
      }

    },

    getIntegration(id) {
      this.$http
        .$get("integration/" + id)
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPlatforms() {
      this.$http
        .$get("platform")
        .then((response) => {
          this.platforms = response.data.data;
          console.log('plataformas', this.platforms);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    close(){
      this.dialog = false
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        if (this.isUpdating) {
          this.$http
            .$put(`/integration/${this.form.id}`, this.form)
            .then((res) => {
              if (res.status == 200) {
                this.dialog = false;
                this.$emit("onSubmit");
              }
            });
        } else {
          this.$http.$post("/integration", this.form).then((res) => {
            if (res.status == 200) {
              this.dialog = false;
              this.$emit("onSubmit");
            }
          });
        }        
      }
    },

  },
  watch:{
    dialog(){
      if (!this.dialog) {
        this.$refs.form.reset()
      }
    }
  }
};
</script>